<template>
  <el-form
    ref="form"
    :model="form"
    size="small"
    label-width="150px"
    label-position="left"
  >
    <el-form-item
      :label="label"
      :prop="prop"
    >
      <UploadImage
        :limit="10"
        :file-list.sync="coverList"
        :disabled="disabled"
      />
    </el-form-item>
  </el-form>
</template>

<script>
import { userZoneList } from '@/utils/selectOptions.js'

export default {
  name: 'FormItemForLoadingSwiper',
  props: {
    form: {
      type: Object,
      default: () => ({})
    },
    label: {
      type: String,
      default: ''
    },
    prop: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    area: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      coverList: []
    }
  },
  watch: {
    'form.loadCarousel': {
      handler (val) {
        this.coverList = (val || []).map(item => {
          const fileExtension = item.split('.').pop().toLowerCase()
          return {
            extend: fileExtension,
            key: item,
            url: item
          }
        })
      },
      immediate: true
    }
  },
  methods: {
    validate () {
      return new Promise((resolve, reject) => {
        this.$refs.form.validate((valid) => {
          if (valid) {
            const loadCarousel = this.coverList.map(item => item.key)
            resolve({ loadCarousel })
          } else {
            const result = userZoneList.find(item => item.value === this.area)
            reject(new Error(result.name + '校验未通过, 请检查相关表单'))
          }
        })
      })
    }
  }
}
</script>

<style lang="less">

</style>
