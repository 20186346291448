<template>
  <el-form
    ref="form"
    :model="form"
    :rules="rules"
    size="small"
    :label-width="labelWidth"
    label-position="left"
  >
    <el-form-item
      :label="label"
      :prop="prop"
    >
      <el-select
        v-model="form.tag"
        multiple
        filterable
        remote
        reserve-keyword
        placeholder="请输入关键词"
        :remote-method="remoteSearch"
        :loading="loading"
        value-key="tagId"
        style="width: 420px;"
      >
        <el-option
          v-for="item in form.localTagList"
          :key="item.tagId"
          :label="item.tagName"
          :value="item"
        />
      </el-select>
    </el-form-item>
  </el-form>
</template>

<script>
import { cloneDeep } from 'lodash'
import { getGameTagList } from '@/api/gameList'
export default {
  name: 'FormItemForTagSearch',
  props: {
    form: {
      type: Object,
      default: () => ({})
    },
    rules: {
      type: Object,
      default: () => ({})
    },
    label: {
      type: String,
      default: ''
    },
    prop: {
      type: String,
      default: ''
    },
    labelWidth: {
      type: String,
      default: '150px'
    }
  },
  data () {
    return {
      loading: false
    }
  },
  methods: {
    remoteSearch (keyWord) {
      if (keyWord !== '') {
        this.loading = true
        getGameTagList({ keyWord, pageSize: 20 })
          .then(res => {
            this.form.localTagList = res?.data?.list?.map(item => {
              return {
                tagId: item.tagIdStr,
                tagName: item.tagName
              }
            })
          })
          .finally(() => {
            this.loading = false
          })
      } else {
        this.form.tag = []
      }
    },
    validate () {
      return new Promise((resolve) => {
        this.$refs.form.validate((valid) => {
          if (valid) {
            const tag = cloneDeep(this.form)?.tag?.map(item => item.tagId)
            resolve({ tag })
          }
        })
      })
    }
  }
}
</script>

<style lang="less">

</style>
