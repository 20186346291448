<template>
  <el-form
    ref="form"
    :model="form"
    :rules="rules"
    size="small"
    label-width="150px"
    label-position="left"
  >
    <el-form-item
      :label="label"
      :prop="prop"
    >
      <UploadImage
        :limit="6"
        :file-list.sync="form.gameResours"
      />
    </el-form-item>
  </el-form>
</template>

<script>
import { cloneDeep } from 'lodash'
export default {
  name: 'FormItemForSwiper',
  props: {
    form: {
      type: Object,
      default: () => ({})
    },
    rules: {
      type: Object,
      default: () => ({})
    },
    label: {
      type: String,
      default: ''
    },
    prop: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      swiperList: []
    }
  },
  methods: {
    validate () {
      return new Promise((resolve) => {
        this.$refs.form.validate((valid) => {
          if (valid) {
            const { gameResours } = cloneDeep(this.form)
            resolve({ gameResours: gameResours.map(item => item.key) })
          }
        })
      })
    }
  }
}
</script>

<style lang="less">

</style>
