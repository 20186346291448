<template>
  <el-card
    class="StartModePlatform card"
    :shadow="disabled ? 'never' : 'hover'"
  >
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      size="small"
      label-width="120px"
    >
      <el-form-item
        label="平台类型"
        prop="gamePlatform"
      >
        <el-radio-group
          v-model="form.gamePlatform"
          :disabled="disabled"
        >
          <el-radio
            v-for="(item, index) in GAME_PLATFORM_LIST"
            :key="index"
            :label="item.value"
          >
            {{ item.name }}
          </el-radio>
        </el-radio-group>
      </el-form-item>

      <el-card
        v-if="form.gamePlatform === GAME_PLATFORM.Steam"
        shadow="never"
      >
        <el-form-item
          label="模式10"
          prop="steamNewStartUp"
        >
          <el-radio-group
            v-model="form.steamNewStartUp"
            :disabled="disabled"
          >
            <el-radio
              v-for="(item, index) in MODE_10_SUPPORT_LIST"
              :key="index"
              :label="item.value"
            >
              {{ item.name }}
            </el-radio>
          </el-radio-group>
        </el-form-item>

        <template v-if="form.steamNewStartUp === MODE_10_SUPPORT.ON">
          <GameLang
            ref="GameLang"
            label="游戏语言"
            :form="form"
            prop="steamGameLangList"
            :disabled="disabled"
          />

          <el-form-item
            label="Steam启动参数"
            prop="SteamStartParam"
          >
            <el-input
              v-model="form.SteamStartParam"
              placeholder="e.g. -noverifyfiles -no-browser -nofriendsui %-tenfoot% -language %language%"
              clearable
              :disabled="disabled"
            />
          </el-form-item>

          <el-form-item
            label="游戏参数"
            prop="GameStartParam"
          >
            <el-input
              v-model="form.GameStartParam"
              placeholder="e.g. -language %language%"
              clearable
              :disabled="disabled"
            />
          </el-form-item>
        </template>
      </el-card>

      <el-card
        v-if="form.gamePlatform !== GAME_PLATFORM.Uplay"
        shadow="never"
        style="margin-top: 30px;"
      >
        <el-form-item
          v-if="form.gamePlatform === GAME_PLATFORM.Epic"
          label="启动ID"
          prop="epicId"
        >
          <el-input
            v-model="form.epicId"
            placeholder="epicId"
            clearable
            :disabled="disabled"
          />
        </el-form-item>

        <el-form-item
          label="租号模式"
          prop="rentalSupport"
        >
          <el-radio-group
            v-model="form.rentalSupport"
            :disabled="disabled"
          >
            <el-radio
              v-for="(item, index) in RENTAL_SUPPORT_LIST"
              :key="index"
              :label="item.value"
            >
              {{ item.name }}
            </el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item
          label="租号预启动路径"
          prop="rentalPreStartPath"
        >
          <el-input
            v-model="form.rentalPreStartPath"
            placeholder="rentalPreStartPath"
            clearable
            :disabled="disabled"
          />
        </el-form-item>
        <el-form-item
          v-if="form.rentalSupport === RENTAL_SUPPORT.ON"
          label="账号组"
        >
          <el-input
            :value="form.accountGroup"
            :disabled="disabled"
            readonly
            placeholder="该项不可编辑"
          />
        </el-form-item>

        <el-form-item
          label="离线模式"
          prop="offlineMode"
        >
          <el-radio-group
            v-model="form.offlineMode"
            :disabled="disabled"
          >
            <el-radio
              v-for="(item, index) in OFFLINE_MODE_LIST"
              :key="index"
              :label="item.value"
            >
              {{ item.name }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="离线预启动路径"
          prop="offlinePreStartPath"
        >
          <el-input
            v-model="form.offlinePreStartPath"
            placeholder="offlinePreStartPath"
            clearable
            :disabled="disabled"
          />
        </el-form-item>
        <el-form-item
          label="进程名"
          prop="startProcess"
        >
          <el-input
            v-model="form.startProcess"
            placeholder="startProcess"
            clearable
            :disabled="disabled"
          />
        </el-form-item>

        <el-form-item
          label="启动路径"
          prop="startPath"
        >
          <el-input
            v-model="form.startPath"
            placeholder="startPath"
            type="textarea"
            clearable
            :disabled="disabled"
            :autosize="{ minRows: 4 }"
          />
        </el-form-item>

        <br>

        <el-form-item
          label="窗口标题"
          prop="windowTitle"
        >
          <el-input
            v-model="form.windowTitle"
            placeholder="windowTitle"
            clearable
            :disabled="disabled"
          />
        </el-form-item>

        <el-form-item
          label="窗口类名"
          prop="windowClass"
        >
          <el-input
            v-model="form.windowClass"
            placeholder="windowClass"
            clearable
            :disabled="disabled"
          />
        </el-form-item>

        <el-form-item
          label="预启动路径"
          prop="preStartPath"
        >
          <el-input
            v-model="form.preStartPath"
            placeholder="preStartPath"
            type="textarea"
            clearable
            :disabled="disabled"
            :autosize="{ minRows: 3 }"
          />
        </el-form-item>
      </el-card>
      <el-card
        v-if="form.gamePlatform !== GAME_PLATFORM.Uplay"
        shadow="never"
        style="margin-top: 30px;"
      >
        <el-form-item
          label="破解模式"
          prop="withoutAccMode"
        >
          <el-radio-group
            v-model="form.withoutAccMode"
            :disabled="disabled"
          >
            <el-radio
              v-for="(item, index) in CRACK_MODE_LIST"
              :key="index"
              :label="item.value"
            >
              {{ item.name }}
            </el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item
          label="破解预启动"
          prop="withoutAccPreStartPath"
        >
          <el-input
            v-model="form.withoutAccPreStartPath"
            placeholder="withoutAccPreStartPath"
            clearable
            :disabled="disabled"
          />
        </el-form-item>
        <el-form-item
          label="破解进程名"
          prop="withoutAccProcessName"
        >
          <el-input
            v-model="form.withoutAccProcessName"
            placeholder="withoutAccProcessName"
            clearable
            :disabled="disabled"
          />
        </el-form-item>

        <el-form-item
          label="破解启动路径"
          prop="withoutAccStartPath"
        >
          <el-input
            v-model="form.withoutAccStartPath"
            placeholder="withoutAccStartPath"
            type="textarea"
            clearable
            :disabled="disabled"
            :autosize="{ minRows: 4 }"
          />
        </el-form-item>
      </el-card>
    </el-form>
  </el-card>
</template>

<script>
import { GAME_PLATFORM_LIST, userZoneList } from '@/utils/selectOptions.js'
import { cloneDeep } from 'lodash'
import { GAME_PLATFORM } from '@/enum'
import GameLang from '@/views/game/game-edit/components/game-mode/GameLang.vue'

const RENTAL_SUPPORT = {
  ON: 1,
  OFF: 0
}

const RENTAL_SUPPORT_LIST = [
  { name: '支持', value: RENTAL_SUPPORT.ON },
  { name: '不支持', value: RENTAL_SUPPORT.OFF }
]

const MODE_10_SUPPORT = {
  ON: 1,
  OFF: 0
}

const MODE_10_SUPPORT_LIST = [
  { name: '支持', value: MODE_10_SUPPORT.ON },
  { name: '不支持', value: MODE_10_SUPPORT.OFF }
]

const OFFLINE_MODE = {
  ON: 2,
  OFF: 1
}

const OFFLINE_MODE_LIST = [
  { name: '支持', value: OFFLINE_MODE.ON },
  { name: '不支持', value: OFFLINE_MODE.OFF }
]

const CRACK_MODE = {
  ON: 1,
  OFF: 0
}
const CRACK_MODE_LIST = [
  { name: '支持', value: CRACK_MODE.ON },
  { name: '不支持', value: CRACK_MODE.OFF }
]
export default {
  name: 'Platform',
  components: { GameLang },
  props: {
    form: {
      type: Object,
      default: () => ({})
    },
    rules: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false
    },
    area: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      GAME_PLATFORM,
      GAME_PLATFORM_LIST,
      RENTAL_SUPPORT,
      RENTAL_SUPPORT_LIST,
      OFFLINE_MODE,
      OFFLINE_MODE_LIST,
      CRACK_MODE,
      CRACK_MODE_LIST,
      MODE_10_SUPPORT,
      MODE_10_SUPPORT_LIST
    }
  },
  methods: {
    validate () {
      return new Promise((resolve, reject) => {
        Promise.all([
          this.$refs.form.validate(),
          this.$refs.GameLang.validate()
        ]).then(list => {
          const valid = list[0]
          const GameLang = list[1]
          if (valid) {
            const {
              gamePlatform,
              steamNewStartUp,
              SteamStartParam,
              GameStartParam,
              rentalSupport,
              epicId,
              accountGroup,
              offlineMode,
              startProcess,
              startPath,
              windowTitle,
              windowClass,
              preStartPath,
              rentalPreStartPath,
              offlinePreStartPath,
              withoutAccMode,
              withoutAccStartPath,
              withoutAccProcessName,
              withoutAccPreStartPath
            } = cloneDeep(this.form)
            const commonForSteamAndEpic = {
              gamePlatform,
              rentalSupport,
              accountGroup,
              offlineMode,
              startProcess,
              startPath,
              windowTitle,
              windowClass,
              offlinePreStartPath,
              preStartPath,
              rentalPreStartPath,
              withoutAccMode,
              withoutAccStartPath,
              withoutAccProcessName,
              withoutAccPreStartPath
            }
            if (gamePlatform === GAME_PLATFORM.Steam) {
              resolve({
                steamNewStartUp,
                SteamStartParam,
                GameStartParam,
                ...GameLang,
                ...commonForSteamAndEpic
              })
            } else if (gamePlatform === GAME_PLATFORM.Epic) {
              resolve({
                epicId,
                ...commonForSteamAndEpic
              })
            } else {
              resolve({ gamePlatform })
            }
          } else {
            const result = userZoneList.find(item => item.value === this.area)
            reject(new Error(result.name + '校验未通过, 请检查相关表单'))
          }
        })
      })
    }
  }
}
</script>
<style lang="less">
.StartModePlatform {
  width: 600px;
  margin-left: 50px;
  .el-input,
  .el-textarea {
    width: 400px !important;
  }
  .el-form-item__label {
    text-align: right;
  }
}
</style>
