<template>
  <el-card
    class="StartModeNormal card"
    :shadow="disabled ? 'never' : 'hover'"
  >
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      size="small"
      label-width="100px"
      label-position="right"
    />
  </el-card>
</template>

<script>

export default {
  name: 'Normal',
  props: {
    form: {
      type: Object,
      default: () => ({})
    },
    rules: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    validate () {
      return new Promise((resolve) => {
        this.$refs.form.validate((valid) => {
          if (valid) {
            resolve({})
          }
        })
      })
    }
  }
}
</script>

<style lang="less">
  .StartModeNormal {
    width: 600px;
    margin-left: 50px;
    .el-input,
    .el-textarea {
      width: 400px !important;
    }
  }
</style>
