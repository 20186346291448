<template>
  <div class="SettingZone">
    <h3>地区配置信息</h3>
    <el-tabs v-model="currentArea">
      <el-tab-pane
        v-for="(zone, index) in userZoneList"
        :key="index"
        :label="zone.name"
        :name="zone.value"
      >
        <Zone
          ref="zone"
          :key="index"
          :zone-data-info="zoneData"
          :zone-index="zone.value"
          :area="zone.value"
          :form="zoneData[zone.value]"
          @reset="handleResetArea"
          @fetchZoneDate="fetchZoneDate"
          @validateFail="handleValidateFail"
        />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { userZoneList } from '@/utils/selectOptions.js'
import { USER_AREA } from '@/enum'
import Zone from '@/views/game/game-edit/components/Zone.vue'
import { cloneDeep } from 'lodash'

const GAME_STATUS = {
  ON: 1,
  OFF: 2
}

const GAME_MODE = {
  normal: -1,
  application: 1,
  platform: -2
}

export default {
  name: 'SettingZone',
  components: { Zone },
  props: {
    form: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      zoneData: {},
      userZoneList,
      currentArea: '0',
      isValidateFail: false
    }
  },
  watch: {
    form: {
      handler () {
        // console.log('formWarch', 'init')
        this.init()
      },
      immediate: true
    }
  },
  // created () {
  //   const unwatch = this.$watch(
  //     'form',
  //     function (form) {
  //       if (!form) return
  //       if (form.zone) {
  //         console.log('formWarch', 'init')
  //         this.init()
  //         if (unwatch) { unwatch() }
  //       }
  //     },
  //     { immediate: true }
  //   )
  // },
  methods: {
    fetchZoneDate (index) {
      this.zoneData[index] = cloneDeep(this.zoneData[0])
      this.zoneData[index].docsId = ''
      this.zoneData[index].docsIdStr = ''
    },
    init () {
      this.zoneData = {
        [USER_AREA.History]: {
          gameStatus: GAME_STATUS.OFF,
          gameMode: GAME_MODE.platform
        },
        [USER_AREA.Europe]: {
          gameStatus: GAME_STATUS.OFF,
          gameMode: GAME_MODE.platform
        },
        [USER_AREA.America]: {
          gameStatus: GAME_STATUS.OFF,
          gameMode: GAME_MODE.platform
        },
        [USER_AREA.Arab]: {
          gameStatus: GAME_STATUS.OFF,
          gameMode: GAME_MODE.platform
        },
        [USER_AREA.Asia]: {
          gameStatus: GAME_STATUS.OFF,
          gameMode: GAME_MODE.platform
        },
        [USER_AREA.Other]: {
          gameStatus: GAME_STATUS.OFF,
          gameMode: GAME_MODE.platform
        }
      }
      // console.log(cloneDeep(this.zoneData), cloneDeep(this.form.zone))
      this.zoneData = Object.assign(this.zoneData, cloneDeep(this.form.zone))
      // console.log(cloneDeep(this.zoneData))
      if (!this.zoneData[0].pcBackPath) {
        this.zoneData[0].pcBackPath = 'generalBg.bmp'
      }
    },
    validate () {
      return Promise.all(this.$refs.zone.map(item => item.validate()))
    },
    handleValidateFail (payload) {
      if (this.isValidateFail) return
      this.isValidateFail = true
      setTimeout(() => {
        this.isValidateFail = false
        this.currentArea = payload.area
        this.$message.error(payload.message)
      })
    },
    handleResetArea (area) {
      const targetArea = cloneDeep(this.form?.zone?.[area] || {})
      targetArea.gameStatus = GAME_STATUS.OFF
      this.$set(this.zoneData, area, targetArea)
    }
  }
}
</script>

<style lang="less">

</style>
