<template>
  <div class="SettingBase">
    <h3>基本配置信息</h3>
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      size="small"
      label-width="150px"
      label-position="left"
    >
      <FormItemForSourceId
        ref="FormItemForSourceId"
        class="formItem"
        label="游戏ID"
        :form="form.basic"
        :rules="rules"
        prop="sourceId"
      />

      <FormItemForEngineRoom
        :form="form.basic"
        :rules="rules"
        :game-room-list="gameRoomList"
        prop="engineRoomInfo"
        label="游戏所在机房"
      />
      <el-form-item label="资源池">
        <el-select
          v-model="form.basic.resId"
          placeholder="请选择"
          style="width: 200px"
        >
          <el-option
            label="默认"
            :value="parseInt('0')"
          />
          <el-option
            v-for="(item, index) in resPoolList"
            :key="index"
            :label="item.name + ' - ' + item.resource_code"
            :value="item.resource_code_int"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="VR游戏">
        <el-radio-group v-model="form.basic.vrMode">
          <el-radio :label="2">
            是
          </el-radio>
          <el-radio :label="1">
            否
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <!-- <el-form-item label="游戏存档">
        <el-radio-group v-model="form.basic.supportOnFile">
          <el-radio :label="GAME_ARCHIVE.support">
            开启
          </el-radio>
          <el-radio :label="GAME_ARCHIVE.unsupport">
            关闭
          </el-radio>
        </el-radio-group>
      </el-form-item> -->

      <!-- <el-form-item label="操作支持">
        <el-checkbox-group v-model="form.basic.play">
          <el-checkbox
            v-for="(item, index) in supportOperationList"
            :key="index"
            :label="item.value"
          >
            {{ item.name }}
          </el-checkbox>
        </el-checkbox-group>
      </el-form-item> -->

      <FormItemForTagSearch
        ref="FormItemForTagSearch"
        label="标签"
        prop="tag"
        class="formItem"
        :form="form"
        :rules="rules"
      />

      <el-form-item label="功能标签">
        <el-checkbox-group v-model="form.basic.feature_tags">
          <el-checkbox
            v-for="item in GameFeatureTags"
            :key="item.value"
            :label="item.value"
            :value="item.value"
          >{{ item.name }}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>

      <el-form-item label="存档Id">
        <el-input v-model="form.basic.archive_id" />
      </el-form-item>

      <el-form-item label="方案类型">
        <el-radio-group v-model="form.basic.type">
          <el-radio
            v-for="(item, index) in keyboardPlanTypeList"
            :key="index"
            :label="item.value"
          >
            {{ item.name }}
          </el-radio>
        </el-radio-group>
      </el-form-item>

      <FormItemForKeyboardPlan
        v-if="form.basic.type === 1"
        ref="FormItemForKeyboardPlan"
        label="配键方案"
        :form="form.basic"
        :rules="rules"
        prop="keyId"
      />

      <el-form-item label="操作模式">
        <el-select
          v-model="form.basic.operationMode"
          clearable
          placeholder="选择操作模式"
        >
          <el-option
            v-for="item in operationModeList"
            :key="item.value"
            :label="item.name"
            :value="item.value"
          />
        </el-select>
      </el-form-item>

      <FormItemForSwiper
        ref="FormItemForSwiper"
        label="详情页轮播"
        :form="form"
        :rules="rules"
        prop="gameResours"
      />
    </el-form>
  </div>
</template>

<script>
import {
  supportOperationList,
  keyboardPlanTypeList,
  operationModeList,
  GameFeatureTags
} from '@/utils/selectOptions.js'

import FormItemForSourceId from '@/views/game/game-edit/components/FormItemForSourceId.vue'
import FormItemForEngineRoom from '@/views/game/game-edit/components/FormItemForEngineRoom.vue'

import FormItemForTagSearch from '@/views/game/game-edit/components/FormItemForTagSearch.vue'
import FormItemForKeyboardPlan from '@/views/game/game-edit/components/FormItemForKeyboardPlan.vue'
import FormItemForSwiper from '@/views/game/game-edit/components/FormItemForSwiper.vue'

import { cloneDeep } from 'lodash'

const GAME_ARCHIVE = {
  support: 1,
  unsupport: 0
}
export default {
  name: 'SettingBase',
  components: {
    FormItemForSourceId,
    FormItemForEngineRoom,
    FormItemForKeyboardPlan,
    FormItemForTagSearch,
    FormItemForSwiper
  },
  props: {
    form: {
      type: Object,
      default: () => ({})
    },
    gameRoomList: {
      type: Array,
      default: () => ([])
    },
    resPoolList: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    const numberCheck = (rule, value, callback) => {
      if (isNaN(value)) {
        callback(new Error('此项必须为数字'))
      } else {
        callback()
      }
    }
    const swiperValidator = (rule, value, callback) => {
      if (!this.form.gameResours.length) {
        callback(new Error('详情页轮播图是必填项'))
      } else {
        callback()
      }
    }
    return {
      GAME_ARCHIVE,
      keyboardPlanTypeList,
      supportOperationList,
      operationModeList,
      GameFeatureTags,
      rules: {
        sourceId: [
          { required: true, message: 'sourceId是必填项' },
          { validator: numberCheck }
        ],
        tag: [
          { required: true, message: '标签是必填项' }
        ],
        gameResours: [
          { required: true, validator: swiperValidator }
        ]
      }
    }
  },
  mounted () {
    console.log('vrMode', this.form.basic.vrMode)
  },
  methods: {
    validate () {
      if (this.$refs.FormItemForKeyboardPlan) {
        return Promise.all([
          this.checkFormValidate(),
          this.$refs.FormItemForSourceId.validate(),
          this.$refs.FormItemForTagSearch.validate(),
          this.$refs.FormItemForKeyboardPlan.validate(),
          this.$refs.FormItemForSwiper.validate()
        ])
      } else {
        return Promise.all([
          this.checkFormValidate(),
          this.$refs.FormItemForSourceId.validate(),
          this.$refs.FormItemForTagSearch.validate(),
          this.$refs.FormItemForSwiper.validate()
        ])
      }
    },
    checkFormValidate () {
      return new Promise(resolve => {
        this.$refs.form.validate((valid) => {
          if (valid) {
            resolve(cloneDeep(this.form))
          }
        })
      })
    }
  }
}
</script>

<style lang="less">

</style>
