<template>
  <el-form
    ref="form"
    :model="form"
    :rules="rules"
    size="small"
    label-width="150px"
    label-position="left"
  >
    <el-form-item
      class="FormItemForEngineRoom"
      :label="label"
      :prop="prop"
    >
      <!-- <el-checkbox-group
        v-model="form.engineRoomInfo"
        @change="handleCheckedCitiesChange"
      >
        <el-checkbox
          v-for="(item, index) in gameRoomList"
          :key="index"
          :label="item.roomId"
        >
          {{ item.roomName }}
        </el-checkbox>
      </el-checkbox-group> -->
      <el-checkbox-group
        v-model="form.engineRoomInfo"
        @change="handleCheckedCitiesChange"
      >
        <el-checkbox
          v-for="(item, index) in gameRoomList"
          :key="index"
          :label="item.roomId"
        >
          {{ item.roomName }}
        </el-checkbox>
      </el-checkbox-group>
    </el-form-item>
  </el-form>
</template>

<script>
import { cloneDeep } from 'lodash'
export default {
  name: 'FormItemForEngineRoom',
  props: {
    form: {
      type: Object,
      default: () => ({
        engineRoomInfo: []
      })
    },
    rules: {
      type: Object,
      default: () => ({})
    },
    label: {
      type: String,
      default: ''
    },
    prop: {
      type: String,
      default: ''
    },
    gameRoomList: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      selectedEngineRooms: [] // 新增选中的数据属性
    }
  },
  watch: {
    selectedEngineRooms: {
      handler (newSelectedEngineRooms) {
        this.selectedEngineRooms = newSelectedEngineRooms
      },
      immediate: true
    }
  },
  methods: {
    validate () {
      return new Promise((resolve) => {
        this.$refs.form.validate((valid) => {
          if (valid) {
            resolve(cloneDeep(this.form))
          }
        })
      })
    },
    // ----------
    handleCheckedCitiesChange () {
      this.$emit('update:form', { ...this.form, engineRoomInfo: this.selectedEngineRooms })
    }
  }
}
</script>

<style lang="less">
  .FormItemForEngineRoom {
    width: 770px;
  }
</style>
