<template>
  <div class="International">
    <el-form
      ref="form"
      :model="form"
      :rules="form.gameStatus === GAME_STATUS.ON ? rules : {}"
      size="small"
      label-width="150px"
      label-position="left"
    >
      <el-button
        v-if="zoneIndex != 0 && form.gameStatus === 1"
        style="margin: 30px;margin-left: 0px;"
        @click="fetchDate"
      >
        同步历史数据
      </el-button>
      <el-form-item
        label="是否启用"
        prop="gameStatus"
      >
        <el-switch
          v-model="form.gameStatus"
          :active-value="GAME_STATUS.ON"
          :inactive-value="GAME_STATUS.OFF"
          active-color="#13ce66"
          inactive-color="#ff4949"
        />
      </el-form-item>

      <FormItemForStartMode
        ref="FormItemForStartMode"
        :form="form"
        :disabled="disabled"
        label="启动模式"
        :area="area"
      />

      <el-form-item
        label="游戏名"
        prop="gameName"
      >
        <el-input
          v-model="form.gameName"
          placeholder="单行输入"
          :disabled="disabled"
        />
      </el-form-item>
      <el-form-item
        label="搜索标签"
      >
        <el-input
          v-model="form.gameKeyword"
          placeholder="单行输入"
          :disabled="disabled"
        />
      </el-form-item>
      <el-form-item
        label="游戏简介"
        prop="gameDesc"
      >
        <el-input
          v-model="form.gameDesc"
          :disabled="disabled"
          type="textarea"
          :autosize="{ minRows: 4 }"
          placeholder="多行输入"
        />
      </el-form-item>

      <el-form-item
        label="关于游戏"
        prop="gameDetail"
        style="padding-right: 10px;"
      >
        <Tinymce
          v-model="form.gameDetail"
          :height="300"
          :disabled="disabled"
        />
      </el-form-item>

      <el-form-item
        label="桌面背景图"
        prop="pcBackPath"
      >
        <el-input
          v-model="form.pcBackPath"
          placeholder="单行输入"
          :disabled="disabled"
        />
      </el-form-item>
      <FormItemForGameCoverH
        ref="FormItemForGameCoverH"
        label="封面图(横)"
        prop="headerImage"
        :form="form"
        :disabled="disabled"
        :area="area"
      />
      <FormItemForGameCoverV
        ref="FormItemForGameCoverV"
        label="封面图(竖)"
        prop="headerImageVertical"
        :form="form"
        :disabled="disabled"
        :area="area"
      />
      <FormItemForLoadingSwiper
        ref="FormItemForLoadingSwiper"
        label="加载页轮播"
        prop="loadCarousel"
        :form="form"
        :disabled="disabled"
        :area="area"
      />
      <el-form-item
        label="轮播间隔时长"
        prop="timeInterval"
      >
        <el-input-number
          v-model="form.timeInterval"
          style="width: 200px;"
          :min="0"
          :disabled="disabled"
        />
        <span style="margin-left: 8px;">秒</span>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { userZoneList } from '@/utils/selectOptions.js'
import { USER_AREA } from '@/enum'
import { cloneDeep } from 'lodash'
import Tinymce from '@/components/Tinymce/index'

import FormItemForStartMode from '@/views/game/game-edit/components/FormItemForStartMode.vue'
import FormItemForGameCoverH from '@/views/game/game-edit/components/FormItemForGameCoverH.vue'
import FormItemForGameCoverV from '@/views/game/game-edit/components/FormItemForGameCoverV.vue'
import FormItemForLoadingSwiper from '@/views/game/game-edit/components/FormItemForLoadingSwiper.vue'

const GAME_STATUS = {
  ON: 1,
  OFF: 2
}
const emptyModeData = {
  gamePlatform: 0,
  rentalSupport: 0,
  epicId: '',
  accountGroup: '',
  offlineMode: 0,
  startProcess: '',
  startPath: '',
  windowTitle: '',
  windowClass: '',
  preStartPath: '',
  withoutAccMode: 0,
  withoutAccStartPath: '',
  withoutAccProcessName: ''
}
export default {
  name: 'Zone',
  components: {
    FormItemForStartMode,
    Tinymce,
    FormItemForGameCoverH,
    FormItemForGameCoverV,
    FormItemForLoadingSwiper
  },
  props: {
    zoneDataInfo: {
      type: Object,
      default: () => ({})
    },
    form: {
      type: Object,
      default: () => ({})
    },
    zoneIndex: {
      type: String,
      default: ''
    },
    area: {
      type: String,
      default: ''
    }
  },
  data () {
    // const gameStatusValidator = (rule, value, callback) => {
    //   if (this.area === USER_AREA.History && this.form.gameStatus === GAME_STATUS.OFF) {
    //     callback(new Error('历史数据必须启用'))
    //   } else {
    //     callback()
    //   }
    // }
    return {
      USER_AREA,
      GAME_STATUS,
      rules: {
        // gameStatus: [{ validator: gameStatusValidator }],
        gameName: [{ required: true, message: '游戏名称是必填项' }],
        gameDesc: [{ required: true, message: '游戏简介是必填项' }],
        gameDetail: [{ required: true, message: '游戏详情是必填项' }]
      },
      newZoneDataInfo: {}
    }
  },
  computed: {
    disabled () {
      return this.form.gameStatus === GAME_STATUS.OFF
    }
  },
  watch: {
    'form.gameStatus' (valid, oldValid) {
      if ((valid !== oldValid) && valid === GAME_STATUS.OFF) {
        this.$emit('reset', this.area)
      }
      this.$nextTick(() => {
        this.$refs.form.clearValidate()
      })
    }
  },
  methods: {
    fetchDate () {
      this.$emit('fetchZoneDate', this.zoneIndex)
    },
    validate () {
      const baseFiledList = [
        'gameStatus',
        'gameName',
        'gameKeyword',
        'gameDesc',
        'gameDetail',
        'pcBackPath',
        'headerImage',
        'headerImageVertical',
        'loadCarousel',
        'timeInterval'
      ]
      return new Promise(resolve => {
        Promise.all([
          this.checkFormValidate(),
          this.$refs.FormItemForGameCoverH.validate(),
          this.$refs.FormItemForGameCoverV.validate(),
          this.$refs.FormItemForLoadingSwiper.validate(),
          this.$refs.FormItemForStartMode.validate()
        ])
          .then(list => {
            const last = list.pop()
            const baseObj = Object.assign(...list)
            const startModeObj = Object.assign(emptyModeData, last)

            baseFiledList.forEach(key => {
              delete startModeObj[key]
            })
            resolve(Object.assign(baseObj, startModeObj))
          })
          .catch(error => {
            this.$emit('validateFail', {
              area: this.area,
              message: error.message
            })
          })
      })
    },
    checkFormValidate () {
      return new Promise((resolve, reject) => {
        this.$refs.form.validate((valid) => {
          if (valid) {
            resolve(cloneDeep(this.form))
          } else {
            const result = userZoneList.find(item => item.value === this.area)
            reject(new Error(result.name + '校验未通过, 请检查相关表单'))
          }
        })
      })
    }
  }
}
</script>
