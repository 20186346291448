<template>
  <Layout
    v-loading="pageLoading"
    class="GameEditIndex"
  >
    <SettingBase
      ref="base"
      :form="form"
      :game-room-list="gameRoomList"
      :res-pool-list="resPoolList"
    />

    <SettingZone
      ref="zone"
      :form="form"
    />

    <div class="ctrl-area">
      <el-button
        :loading="updateLoading"
        type="primary"
        @click="handleSubmit"
      >
        保存
      </el-button>
      <el-button @click="handleCancel">
        取消
      </el-button>
    </div>
  </Layout>
</template>

<script>
import SettingBase from './components/SettingBase.vue'
import SettingZone from './components/SettingZone.vue'
import { userZoneList } from '@/utils/selectOptions.js'
import { cloneDeep } from 'lodash'
import {
  webGetSingleGameV2,
  getEngineRoomInfo,
  updateGameV2,
  addGameV2,
  updateGameEnableStatus, getResPoolList, getGame, updateGame
} from '@/api/gameList.js'

const GAME_STATUS = {
  ON: 1,
  OFF: 2
}
const GAME_MODE = {
  normal: -1,
  application: 1,
  platform: -2
}
export default {
  name: 'GameEditIndex',
  components: {
    SettingBase,
    SettingZone
  },
  data () {
    return {
      isEdit: false,
      pageLoading: false,
      form: {
        basic: {
          // play: [],
          engineRoomInfo: [],
          feature_tags: ['Single']
        },
        tag: [],
        gameResours: [],
        zone: {},
        localTagList: []
      },
      updateLoading: false,
      gameRoomList: [],
      resPoolList: []
    }
  },
  beforeRouteLeave (to, from, next) {
    if (document.querySelector('.main-view')) {
      sessionStorage.GameEditIndex = document.querySelector(
        '.main-view'
      ).scrollTop
    }
    next()
  },
  // beforeRouteEnter (to, from, next) {
  //   if (from.path === '/home/game/game-list' && sessionStorage.isEdit === '1') {
  //     sessionStorage.isActivated = '1'
  //   } else {
  //     sessionStorage.isActivated = '2'
  //   }
  //   next()
  // },
  created () {
    this.init()
  },
  activated () {
    this.$nextTick(() => {
      if (document.querySelector('.main-view')) {
        document.querySelector('.main-view').scrollTo({
          top: sessionStorage.GameEditIndex,
          left: 0,
          behavior: 'auto'
        })
      }
    })
    if (sessionStorage.isEdit === '1') {
      this.init()
      sessionStorage.isEdit = '2'
    }
  },
  methods: {
    init (resGameIdstr) {
      const gameId = this.$route.query.gameId || resGameIdstr
      Promise.all([
        getEngineRoomInfo({}),
        this.webGetSingleGame(gameId),
        this.getGame(gameId),
        getResPoolList({})
      ]).then(list => {
        const [RoomResult, GameResult, Game, ResPoolResult] = list
        if (RoomResult.code === 200) {
          this.gameRoomList = RoomResult?.data?.list || []
        }
        console.log('this.gameRoomList:', this.gameRoomList)
        if (GameResult?.code === 200) {
          const result = GameResult?.data || {}
          result.basic.archive_id = Game.archive_id || null
          result.basic.feature_tags = Game.feature_tags || ['Single']
          console.log('result:', result)
          this.form = {
            ...this.form,
            ...result,
            gameResours: (result.gameResours || []).map(item => {
              const fileExtension = item.split('.').pop().toLowerCase()
              return {
                extend: fileExtension,
                key: item,
                url: item
              }
            })
          }
          if (!this.form.zone) this.form.zone = {}

          this.$set(this.form.basic, 'type', this.form.basic.keyboardType ?? '')
          if (this.form?.basic?.keyboard) {
            const { keyId } = this.form.basic.keyboard
            this.form.basic.keyId = keyId
          }

          userZoneList.forEach(zone => {
            if (!this.form.zone[zone.value]) {
              // 启用开关默认开启, 启动模式默认平台游戏
              this.$set(this.form.zone, zone.value, { gameStatus: GAME_STATUS.OFF, gameMode: GAME_MODE.platform })
            }
          })
          console.log(this.form, '------')
          // this.form.basic.engineRoomInfo = result?.basic?.engineRoomInfo || []
          const arr = []
          // this.gameRoomList.filter
          for (const room of this.gameRoomList) {
            if (this.form.basic.engineRoomInfo && this.form.basic.engineRoomInfo.includes(room.roomId)) arr.push(room.roomId)
          }
          this.form.basic.engineRoomInfo = arr

          // this.form.basic.engineRoomInfo = result?.basic?.engineRoomInfo?.filter(item => {
          //   return this.gameRoomList.some(room => room.roomId === item)
          // }) || []

          console.log(this.form.basic.engineRoomInfo, '------this.form.basic.engineRoomInfo')
          // this.form.basic.play = (result?.basic?.play || '')?.split(',')
          this.form.localTagList = cloneDeep(this.form.tag)
        }
        if (ResPoolResult.code === 200) {
          const resList = ResPoolResult?.data?.list || []
          resList.forEach(item => {
            item.resource_code_int = parseInt(item.resource_code.slice(3))
          })
          this.resPoolList = resList
        }
        this.pageLoading = false
      })
        .catch(error => {
          console.log(error)
          this.pageLoading = false
        })
    },
    webGetSingleGame (gameId) {
      if (!gameId) return Promise.resolve(false)
      this.isEdit = true
      this.pageLoading = true
      return webGetSingleGameV2({
        gameIdStr: gameId
      })
    },
    getGame (gameId) {
      if (!gameId) return Promise.resolve(false)
      this.isEdit = true
      this.pageLoading = true
      return getGame(gameId)
    },
    handleSubmit () {
      Promise.all([
        this.$refs.base.validate(),
        this.$refs.zone.validate()
      ])
        .then(list => {
          // base
          const baseList = cloneDeep(list[0])
          let result = {}
          baseList.forEach(item => {
            if (!item.gameResours) {
              result = Object.assign(baseList[0].basic, item)
            } else {
              result = Object.assign(baseList[0], item)
            }
          })
          // result.basic.play = result.basic?.play?.join(',')
          result.basic.keyId = result.basic.keyId || ''
          delete result.tag
          delete result.localTagList
          // zone
          const zoneList = cloneDeep(list[1])
          const zoneResult = userZoneList.reduce((result, item, index) => {
            result[item.value] = zoneList[index]
            return result
          }, {})
          result.zone = cloneDeep(zoneResult)
          const offZoneCodeList = []
          Object.keys(result.zone).forEach(zoneCode => {
            if (result.zone[zoneCode].gameStatus === GAME_STATUS.OFF) {
              delete result.zone[zoneCode]
              offZoneCodeList.push(zoneCode)
            }
            // else {
            //   result.zone[zoneCode].pcBackPath = result.zone[zoneCode].pcBackPath || 'generalBg.bmp'
            // }
          })

          if (!Object.keys(result.zone).length) {
            return this.$message.error('至少启用一个区')
          }
          if (this.isEdit) {
            this.updateLoading = true
            if (offZoneCodeList.length) {
              updateGameEnableStatus({
                gameIdStr: this.$route.query.gameId,
                status: 2, // 默认1    '1.正常，2.禁用',
                zoneId: offZoneCodeList
              }).then(res => {
                if (res.code === 200) {
                  this.updateGame(result)
                } else {
                  this.updateLoading = false
                }
              })
                .catch(error => {
                  console.log(error)
                  this.updateLoading = false
                })
            } else {
              this.updateGame(result)
            }
          } else {
            this.addGame(result)
          }
        })
    },
    updateGame (data) {
      console.log(data, '-----------')
      updateGameV2(data)
        .then(res => {
          if (res.code === 200) {
            updateGame(data.basic.gameIdStr, { archive_id: data.basic.archive_id, feature_tags: data.basic.feature_tags }).then(() => {
              this.$notify({
                title: '成功',
                message: '更新成功',
                type: 'success'
              })
            })
          }
        })
        .finally(() => {
          this.updateLoading = false
        })
    },
    addGame (data) {
      this.updateLoading = true
      addGameV2(data)
        .then(res => {
          if (res.code === 200) {
            updateGame(res.data.gameId, { archive_id: data.basic.archive_id, feature_tags: data.basic.feature_tags }).then(() => {
              this.init(res.data.gameId)
              this.$notify({
                title: '成功',
                message: '新建游戏成功',
                type: 'success'
              })
            })
          }
        })
        .finally(() => {
          this.updateLoading = false
        })
    },
    handleCancel () {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="less">
.GameEditIndex {
  min-width: 970px;
  .layout-content {
    padding: 20px 60px  80px !important;
  }
  .el-input,
  .el-textarea {
    width: 620px;
  }
  .el-input-number {
    .el-input {
      width: auto;
    }
  }
  .ctrl-area{
    margin-top: 80px;
    .el-button{
      padding:10px  40px;
    }
  }
}
</style>
