<template>
  <el-form
    ref="form"
    :model="form"
    :rules="form.gameStatus === GAME_STATUS.ON ? rules : {}"
    size="small"
    label-width="150px"
    label-position="left"
  >
    <el-form-item
      :label="label"
      :prop="prop"
    >
      <UploadImage
        :file-list.sync="coverList"
        :disabled="disabled"
      />
    </el-form-item>
  </el-form>
</template>

<script>
import { userZoneList } from '@/utils/selectOptions.js'
const GAME_STATUS = {
  ON: 1,
  OFF: 2
}
export default {
  name: 'FormItemForGameCoverH',
  props: {
    form: {
      type: Object,
      default: () => ({})
    },
    label: {
      type: String,
      default: ''
    },
    prop: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    area: {
      type: String,
      default: ''
    }
  },
  data () {
    const coverValidator = (rule, value, callback) => {
      if (this.coverList.length) {
        return callback()
      } else {
        callback(new Error('游戏横图必须上传'))
      }
    }
    return {
      GAME_STATUS,
      coverList: [],
      rules: {
        headerImage: [
          { required: true, validator: coverValidator }
        ]
      }
    }
  },
  watch: {
    'form.gameStatus' () {
      this.$nextTick(() => {
        this.$refs.form.clearValidate()
      })
    },
    'form.headerImage': {
      handler (val) {
        if (val) {
          this.coverList = [{ url: val, key: val }]
        } else {
          this.coverList = []
        }
      },
      immediate: true
    }
  },

  methods: {
    validate () {
      return new Promise((resolve, reject) => {
        this.$refs.form.validate((valid) => {
          if (valid) {
            const headerImage = this.coverList[0]?.key
            resolve({ headerImage })
          } else {
            const result = userZoneList.find(item => item.value === this.area)
            reject(new Error(result.name + '校验未通过, 请检查相关表单'))
          }
        })
      })
    }
  }
}
</script>

<style lang="less">

</style>
