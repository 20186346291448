<template>
  <el-form
    ref="form"
    class="FormItemForStartMode"
    :model="form"
    :rules="rules"
    size="small"
    label-width="150px"
    label-position="left"
  >
    <el-form-item
      :label="label"
      :prop="prop"
    >
      <el-radio-group
        v-model="form.gameMode"
        :disabled="disabled"
        @change="handleStartModeChange"
      >
        <el-radio
          v-for="(item, index) in GAME_MODE_LIST"
          :key="index"
          :label="item.value"
        >
          {{ item.name }}
        </el-radio>
      </el-radio-group>
    </el-form-item>
    <div class="mode-frame">
      <components
        :is="componentsMap[form.gameMode]"
        ref="gameModeInfo"
        :form="form"
        :disabled="disabled"
        :area="area"
      />
    </div>
  </el-form>
</template>

<script>
import { cloneDeep } from 'lodash'
import { GAME_MODE_LIST, userZoneList } from '@/utils/selectOptions.js'
import normal from '@/views/game/game-edit/components/game-mode/normal.vue'
import application from '@/views/game/game-edit/components/game-mode/application.vue'
import platform from '@/views/game/game-edit/components/game-mode/platform.vue'

const componentsMap = {
  '-1': 'normal',
  1: 'application',
  '-2': 'platform'
}
export default {
  name: 'FormItemForStartMode',
  components: {
    normal,
    application,
    platform
  },
  props: {
    form: {
      type: Object,
      default: () => ({})
    },
    rules: {
      type: Object,
      default: () => ({})
    },
    label: {
      type: String,
      default: ''
    },
    prop: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    area: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      componentsMap,
      GAME_MODE_LIST
    }
  },
  methods: {
    validate () {
      return new Promise((resolve, reject) => {
        this.$refs.form.validate((valid) => {
          if (valid) {
            resolve(cloneDeep(this.form))
          } else {
            const result = userZoneList.find(item => item.value === this.area)
            reject(new Error(result.name + '校验未通过, 请检查相关表单'))
          }
        })
      })
    },
    handleStartModeChange () {
      console.log(this.form.gameMode)
    }
  }
}
</script>

<style lang="less">
.FormItemForStartMode {
  .mode-frame {
    margin: 20px 0 40px;
  }
}
</style>
