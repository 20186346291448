<template>
  <el-form
    ref="form"
    :model="form"
    :rules="rules"
    size="small"
    label-width="150px"
    label-position="left"
  >
    <el-form-item
      :label="label"
      :prop="prop"
    >
      <el-input v-model="form.sourceId" />
    </el-form-item>
  </el-form>
</template>

<script>
import { cloneDeep } from 'lodash'
export default {
  name: 'FormItemForSourceId',
  props: {
    form: {
      type: Object,
      default: () => ({})
    },
    rules: {
      type: Object,
      default: () => ({})
    },
    label: {
      type: String,
      default: ''
    },
    prop: {
      type: String,
      default: ''
    }
  },
  methods: {
    validate () {
      return new Promise((resolve) => {
        this.$refs.form.validate((valid) => {
          if (valid) {
            const { sourceId } = cloneDeep(this.form)
            resolve({ sourceId })
          }
        })
      })
    }
  }
}
</script>

<style lang="less">

</style>
