<template>
  <el-card
    class="StartModeApplication card"
    :shadow="disabled ? 'never' : 'hover'"
  >
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      size="small"
      label-width="100px"
      label-position="right"
    >
      <el-form-item
        label="进程名"
        prop="startProcess"
      >
        <el-input
          v-model="form.startProcess"
          placeholder="startProcess"
          clearable
          :disabled="disabled"
        />
      </el-form-item>

      <el-form-item
        label="启动路径"
        prop="startPath"
      >
        <el-input
          v-model="form.startPath"
          placeholder="startPath"
          type="textarea"
          clearable
          :disabled="disabled"
          :autosize="{ minRows: 4 }"
        />
      </el-form-item>

      <br>

      <el-form-item
        label="窗口标题"
        prop="windowTitle"
      >
        <el-input
          v-model="form.windowTitle"
          placeholder="windowTitle"
          clearable
          :disabled="disabled"
        />
      </el-form-item>

      <el-form-item
        label="窗口类名"
        prop="windowClass"
      >
        <el-input
          v-model="form.windowClass"
          placeholder="windowClass"
          clearable
          :disabled="disabled"
        />
      </el-form-item>

      <el-form-item
        label="预启动路径"
        prop="preStartPath"
      >
        <el-input
          v-model="form.preStartPath"
          placeholder="preStartPath"
          clearable
          :disabled="disabled"
        />
      </el-form-item>
    </el-form>
  </el-card>
</template>

<script>
import { cloneDeep } from 'lodash'
import { userZoneList } from '@/utils/selectOptions.js'

export default {
  name: 'Application',
  props: {
    form: {
      type: Object,
      default: () => ({})
    },
    rules: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false
    },
    area: {
      type: String,
      default: ''
    }
  },
  methods: {
    validate () {
      return new Promise((resolve, reject) => {
        this.$refs.form.validate((valid) => {
          if (valid) {
            const {
              startProcess,
              startPath,
              windowTitle,
              windowClass,
              preStartPath
            } = cloneDeep(this.form)
            resolve({
              startProcess,
              startPath,
              windowTitle,
              windowClass,
              preStartPath
            })
          } else {
            const result = userZoneList.find(item => item.value === this.area)
            reject(new Error(result.name + '校验未通过, 请检查相关表单'))
          }
        })
      })
    }
  }
}
</script>

<style lang="less">
  .StartModeApplication {
    width: 600px;
    margin-left: 50px;
    .el-input,
    .el-textarea {
      width: 400px !important;
    }
  }
</style>
