<template>
  <el-form
    ref="form"
    :model="form"
    :rules="rules"
    size="small"
    label-width="120px"
    label-position="left"
  >
    <el-form-item
      class="GameLang"
      :label="label"
      :prop="prop"
    >
      <el-table :data="form.steamGameLangList">
        <el-table-column label="app语言">
          <template #default="{row}">
            <el-input
              v-model="row.AppLang"
              class="table-input"
              placeholder="e.g. en"
            />
          </template>
        </el-table-column>
        <el-table-column label="steam游戏语言">
          <template #default="{row}">
            <el-input
              v-model="row.SteamLang"
              class="table-input"
              placeholder="e.g. english"
            />
          </template>
        </el-table-column>
        <el-table-column width="50px">
          <template #header>
            <el-button
              type="text"
              @click="handleAddItem"
            >
              <i class="icon-button el-icon-circle-plus-outline" />
            </el-button>
          </template>
          <template #default="{$index}">
            <el-button
              type="text"
              @click="handleRemoveItem($index)"
            >
              <i
                class="icon-button el-icon-remove-outline"
              />
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-form-item>
  </el-form>
</template>

<script>
import { cloneDeep } from 'lodash'
export default {
  name: 'GameLang',
  props: {
    form: {
      type: Object,
      default: () => ({})
    },
    rules: {
      type: Object,
      default: () => ({})
    },
    label: {
      type: String,
      default: ''
    },
    prop: {
      type: String,
      default: ''
    }
  },
  methods: {
    handleAddItem () {
      if (!this.form.steamGameLangList) {
        this.form.steamGameLangList = []
      }
      this.form.steamGameLangList.push({
        AppLang: '',
        SteamLang: ''
      })
    },
    handleRemoveItem (index) {
      this.form.steamGameLangList.splice(index, 1)
    },
    validate () {
      return new Promise((resolve) => {
        this.$refs.form.validate((valid) => {
          if (valid) {
            resolve({
              steamGameLangList: cloneDeep(this.form.steamGameLangList)
            })
          }
        })
      })
    }
  }
}
</script>

<style lang="less">
  .GameLang {
    .el-form-item__label {
      margin-top: 18px;
    }
    .icon-button {
      font-size: 24px;
    }
    .table-input {
      &.el-input {
        width: 100% !important;
      }
    }
  }
</style>
