<template>
  <el-form
    ref="form"
    class="FormItemForKeyboardPlan"
    :model="form"
    :rules="rules"
    size="small"
    label-width="150px"
    label-position="left"
  >
    <el-form-item
      :label="label"
      :prop="prop"
    >
      <el-button
        type="text"
        @click="handleSelectKeyboardPlan"
      >
        + 选择预设方案
      </el-button>
    </el-form-item>
    <VirtualKeyboard
      v-if="hasCurrentKeyboard"
      :width="620"
      :data="currentkeyboard"
      class="VirtualKeyboardPlan"
    />

    <el-dialog
      v-loading="keyboardSearchLoading"
      title="选择预设方案"
      :visible.sync="keyboardVisible"
      width="650px"
    >
      <el-form
        inline
        :model="keyboardForm"
      >
        <el-form-item>
          <el-input
            v-model="keyboardForm.userIdStr"
            placeholder="用户ID"
            @keyup.enter.native="handleSearchKeyboard"
          />
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="keyboardForm.keyName"
            placeholder="方案名字"
            @keyup.enter.native="handleSearchKeyboard"
          />
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="handleSearchKeyboard"
          >
            搜索
          </el-button>
        </el-form-item>
        <el-form-item>
          <el-button
            v-show="selectedKeyboadId"
            type="success"
            @click="handleConfirmSelectKeyboard"
          >
            确定使用
          </el-button>
        </el-form-item>
      </el-form>
      <template v-if="keyboardSearchList.length">
        <el-radio-group v-model="selectedKeyboadId">
          <el-radio
            v-for="(item, index) in keyboardSearchList"
            :key="index"
            :label="item.keyId"
            class="keyboardItem"
          >
            <div
              class="keyboardSearchList"
              :class="{active: item.keyId === selectedKeyboadId}"
            >
              <VirtualKeyboard
                :data="item"
                :width="200"
              />
              <div class="keyboardInfo">
                <div class="userId">
                  UserId: {{ item.userIdStr || '/' }}
                </div>
                <div class="keyName">
                  方案名称: {{ item.keyName }}
                </div>
              </div>
            </div>
          </el-radio>
        </el-radio-group>
      </template>
      <template v-else>
        <div style="text-align: center; margin: 30px 0">
          暂无数据
        </div>
      </template>
    </el-dialog>
  </el-form>
</template>

<script>
import { isEmpty } from 'lodash'
import VirtualKeyboard from '@/components/VirtualKeyboard/index.vue'
import { getKeyBoardList } from '@/api/keyboard.js'

export default {
  name: 'FormItemForKeyboardPlan',
  components: { VirtualKeyboard },
  props: {
    form: {
      type: Object,
      default: () => ({})
    },
    rules: {
      type: Object,
      default: () => ({})
    },
    label: {
      type: String,
      default: ''
    },
    prop: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      keyboardSearchList: [],
      keyboardForm: {
        pageNum: 1,
        pageSize: 20
      },
      currentkeyboard: {},
      keyboardVisible: false,
      keyboardSearchLoading: false,
      selectedKeyboadId: '',
      keyboardPlanList: [],
      keyboardPlan: {}
    }
  },
  computed: {
    hasCurrentKeyboard () {
      return !isEmpty(this.currentkeyboard)
    }
  },
  created () {
    const unwatch = this.$watch(
      'form',
      function (form) {
        if (!form) return
        if (form.keyId) {
          this.getCurrentKeyBoard(form.keyId)
          if (unwatch) { unwatch() }
        }
      },
      { immediate: true }
    )
  },
  methods: {
    validate () {
      return new Promise((resolve) => {
        this.$refs.form.validate((valid) => {
          if (valid) {
            const keyId = this.currentkeyboard.keyId || ''
            resolve({ keyId })
          }
        })
      })
    },
    getCurrentKeyBoard (keyId) {
      getKeyBoardList({
        keyId,
        pageNum: 1,
        pageSize: 20
      }).then(res => {
        if (res.code === 200) {
          this.currentkeyboard = res?.data?.list?.[0] || {}
        }
      })
    },
    handleSearchKeyboard () {
      this.keyboardSearchLoading = true
      getKeyBoardList(this.keyboardForm)
        .then(res => {
          if (res.code === 200) {
            this.keyboardSearchList = res?.data?.list || []
          }
        })
        .finally(() => {
          this.keyboardSearchLoading = false
        })
    },
    handleSelectKeyboardPlan () {
      this.keyboardVisible = true
      this.selectedKeyboadId = this.keyboardPlan.keyId
    },
    handleConfirmSelectKeyboard () {
      if (!this.selectedKeyboadId) {
        this.$message.warn('请选择键盘方案')
        return false
      }
      this.keyboardPlan.keyId = this.selectedKeyboadId
      this.currentkeyboard = this.keyboardSearchList.filter(item => {
        return item.keyId === this.selectedKeyboadId
      })[0] || this.currentkeyboard
      this.keyboardVisible = false
    }
  }
}
</script>

<style lang="less">
  .FormItemForKeyboardPlan{

    .el-input,
    .el-textarea {
      width: auto!important;
    }
    .el-radio{
      margin-top: 10px;
    }
    .keyboardItem {
      display: flex;
      align-items: center;
      .keyboardSearchList {
        width: 560px;
        &.active {
          box-shadow: 0 0 8px rgba(130, 41, 229, .2);
        }
      }
    }
    .VirtualKeyboardPlan {
      margin-left: 150px;
      margin-bottom: 50px;
    }
    .keyboardSearchList {
    display: flex;
    margin: 10px 0;
    border: 1px solid #e5e5e5;
    .keyboardInfo {
      margin-left: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      >div {
        margin: 10px 0 ;
      }
    }
  }
  }
</style>
